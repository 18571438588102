import Auth from './auth'

import './middleware'

// Active schemes
import scheme_23514a38 from './schemes/oauth2.js'

export default function (ctx, inject) {
  // Options
  const options = {"resetOnError":false,"scopeKey":"scope","rewriteRedirects":true,"fullPathRedirect":true,"watchLoggedIn":true,"redirect":{"login":"/auth/login","logout":"https://login.strossle.com/v2/logout?returnTo=https%3A%2F%2Fmy.staging.strossle.com","home":"/","callback":"/auth/callback"},"vuex":{"namespace":"auth"},"cookie":{"prefix":"staging.auth.","options":{"path":"/","domain":".staging.strossle.com"}},"localStorage":{"prefix":"auth."},"token":{"prefix":"_token."},"refresh_token":{"prefix":"_refresh_token."},"defaultStrategy":"auth0"}

  // Create a new Auth instance
  const $auth = new Auth(ctx, options)

  // Register strategies
  // auth0
  $auth.registerStrategy('auth0', new scheme_23514a38($auth, {"client_id":"kr5Mg3C6PjR2YyYEkXCwc3yX95fgyBjE","domain":"login.strossle.com","audience":"https://api.staging.strossle.com/","_name":"auth0","authorization_endpoint":"https://login.strossle.com/authorize","userinfo_endpoint":"https://login.strossle.com/userinfo","scope":["openid","profile","email"]}))

  // Inject it to nuxt context as $auth
  inject('auth', $auth)
  ctx.$auth = $auth

  // Initialize auth
  return $auth.init().catch(error => {
    if (process.client) {
      console.error('[ERROR] [AUTH]', error)
    }
  })
}
